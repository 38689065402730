<!-- !PAGE CONTENT! -->
<div class="w3-main" style="margin-left: 340px; margin-right: 40px">
  <!-- Header -->
  <!-- Über uns -->
  <div class="w3-container anchor" id="about">
    <h1 class="w3-xxxlarge hjb-text-secondary"><b>Über uns.</b></h1>
    <hr style="width: 100px; border: 5px solid #44c4d3" class="w3-round" />
    <p>
      Die Jörg Behr Handelsagentur ist Ihr zuverlässiger Partner, wenn es um die
      Sortimentsauswahl von Seidenblumen, Deko-Artikeln und Granulaten geht.
    </p>
    <p>
      Wir sind dabei Ansprechpartner für alle Unternehmen aus Handel,
      Dienstleistung und Projektgeschäft im Großraum Nordrheinwestfalen.
    </p>
    <p>
      Viele unserer Kunden unterstützen und begleiten wir bereits seit über 25
      Jahren. Durch den Wandel im Handel kommt der persönlichen Beratung eine
      zunehmend größere Bedeutung zu. Somit vergrößert sich der Kreis unserer
      Kundschaft kontinuierlich, worüber wir uns sehr freuen.
    </p>
    <p>
      Durch die jahrelange Markterfahrung verfügen wir über eine sehr gute
      Kenntnis der Bedürfnisse der Kunden unserer Kunden. Wir können anhand von
      Verkaufszahlen, dem Know-how unserer Kundschaft, Marktentwicklungen und
      Trends Einschätzungen treffen, wie sich das Kaufverhalten der Endkunden
      entwickeln könnte und unsere Beratung so darauf ausrichten.
    </p>
    <p>
      Aus unseren Kaufgesprächen erhalten wir immer wieder wichtige Impulse,
      welche Trends und Produkte gefragt sind und können aufgrund der engen
      Zusammenarbeit mit unseren Partnerunternehmen auch Einfluss auf die
      zukünftigen Sortimente nehmen. Es entsteht ein Kreislauf, von dem alle
      Seiten profitieren.
    </p>
    <p>
      Auf dem Gebiet der Seidenblumen und Granulate sind wir in Deutschland
      führend was Sortiment und Liefertreue angeht. Unsere Aktualität
      gewährleisten wir dadurch, dass wir jährlich Trends und Innovationen in
      unser Programm aufnehmen – natürlich ohne unser Standard Produktportfolio
      zu verringern.
    </p>
    <p>
      Gerne beraten wir auch Ihr Unternehmen im Hinblick auf unsere
      Produktwelten.
    </p>
    <p>Sprechen Sie uns jederzeit unverbindlich an!</p>
  </div>

  <div class="w3-container anchor" id="showroom">
    <h1 class="w3-xxxlarge hjb-text-secondary"><b>Eindrücke.</b></h1>
    <hr style="width: 100px; border: 5px solid #44c4d3" class="w3-round" />
    <p>
      Für unsere Kundengespräche stehen verschiedene Möglichkeiten zur
      Verfügung. Gerne empfangen wir Sie im Rahmen unserer Hausmesse an unserem
      Standort in der Nähe von Köln. Auf den großen Messen unserer Branche sind
      wir ebenfalls mit einer Präsentationsfläche vertreten und können Ihnen
      dort einen Einblick in unser Sortiment geben. Außerhalb dieser Zeiten
      besuchen wir Sie für eine Beratung auch gern in Ihren Räumlichkeiten vor
      Ort.
    </p>
  </div>

  <!-- Photo grid (modal) -->
  <div class="w3-row-padding">
    <div class="w3-half">
      <img
        src="assets/images/4868_Messe_Hausmesse_Fj-So_2021_0013.jpg"
        style="width: 100%"
        onclick="onClick(this)"
      />
      <img
        src="assets/images/512_Messe_Ambiente_2020_0002.jpg"
        style="width: 100%"
        onclick="onClick(this)"
      />
      <img
        src="assets/images/4857_Messe_Hausmesse_Fj-So_2021_0001.jpg"
        style="width: 100%"
        onclick="onClick(this)"
      />
    </div>

    <div class="w3-half">
      <img
        src="assets/images/527_Messe_Ambiente_2020_0017.jpg"
        style="width: 100%"
        onclick="onClick(this)"
      />
      <img
        src="assets/images/3784_Messe_Hausmesse_X-Mas_2020_0002.jpg"
        style="width: 100%"
        onclick="onClick(this)"
      />
      <img
        src="assets/images/4877_Messe_Hausmesse_Fj-So_2021_0022.jpg"
        style="width: 100%"
        onclick="onClick(this)"
      />
    </div>
  </div>

  <!-- Partner -->
  <div class="w3-container anchor" id="partner">
    <h1 class="w3-xxxlarge hjb-text-secondary"><b>Partner.</b></h1>
    <hr style="width: 100px; border: 5px solid #44c4d3" class="w3-round" />
    <p>
      Mit unseren Partnern arbeiten wir bereits seit über 25 Jahren zusammen.
      Durch diese jahrelange erfolgreiche Zusammenarbeit können wir unseren
      Kunden ein umfangreiches und jederzeit aktuelles Sortiment bei
      bestmöglicher Liefertreue und gutem Preis-/ Leistungsverhältnis bieten.
    </p>
    <p><b>Unsere Partner</b>:</p>
  </div>

  <div class="w3-row-padding">
    <div class="w3-col m6 w3-margin-bottom">
      <div class="w3-light-grey w3-center">
        <h3>Gasper</h3>
        <img
          src="assets/images/GasperLogo_homepageNEU.svg"
          alt="Gasper"
          style="width: 50%"
        />
        <div class="w3-container">
          <p class="w3-opacity">
            Textilpflanzen und -Blumen, Accessoires <br />
            für Haus und Garten
          </p>
          <a href="http://www.gasper.de/" target="_blank">
            <button class="w3-button hjb-primary w3-padding-large">
              Zur Website
            </button>
          </a>
        </div>
      </div>
    </div>
    <div class="w3-col m6 w3-margin-bottom">
      <div class="w3-light-grey w3-center">
        <h3>EUROSAND</h3>
        <img src="/assets/images/eurosand.jpg" alt="Jane" style="width: 50%" />
        <div class="w3-container">
          <p class="w3-opacity">
            Hochwertige Deko-Streuartikel, für Floristik, Tabletop, Glas- und
            Keramikdeko und Dekorateure
          </p>
          <a href="https://www.eurosand.com/" target="_blank">
            <button class="w3-button hjb-primary w3-padding-large">
              Zur Website
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!--Termine -->
  <div class="w3-container anchor" id="appointments">
    <h1 class="w3-xxxlarge hjb-text-secondary"><b>Termine.</b></h1>
    <hr style="width: 100px; border: 5px solid #44c4d3" class="w3-round" />
    <p>Besuchen Sie uns auf unseren Messen oder direkt in unserem Showroom.</p>
  </div>

  <div class="w3-row-padding">
    <div class="w3-col m4 w3-margin-bottom">
      <ul class="w3-ul w3-light-grey w3-center">
        <li class="hjb-primary w3-xlarge w3-padding-32">Christmasworld</li>
        <li class="w3-padding-16">
          internationale Leitmesse für saisonale Dekoration und Festschmuck
        </li>
        <li class="w3-padding-16">
          <h2>Februar 2023</h2>
          <span class="w3-opacity">in Frankfurt am Main</span>
        </li>
        <li class="w3-light-grey w3-padding-24">
          <a
            href="https://christmasworld.messefrankfurt.com/frankfurt/de.html"
            target="_blank"
          >
            <button class="w3-button hjb-primary w3-padding-large">
              Zur Messewebsite
            </button>
          </a>
        </li>
      </ul>
    </div>

    <div class="w3-col m4">
      <ul class="w3-ul w3-light-grey w3-center">
        <li class="hjb-primary w3-xlarge w3-padding-32">Ambiente</li>
        <li class="w3-padding-16">
          internationale Leitmesse für Konsumgüter<br />&nbsp;
        </li>
        <li class="w3-padding-16">
          <h2>Februar 2023</h2>
          <span class="w3-opacity">in Frankfurt am Main</span>
        </li>
        <li class="w3-light-grey w3-padding-24">
          <a
            href="https://ambiente.messefrankfurt.com/frankfurt/de.html"
            target="_blank"
          >
            <button class="w3-button hjb-primary w3-padding-large">
              Zur Messewebsite
            </button>
          </a>
        </li>
      </ul>
    </div>

    <div class="w3-col m4 w3-margin-bottom">
      <ul class="w3-ul w3-light-grey w3-center">
        <li class="hjb-primary w3-xlarge w3-padding-32">Tendence</li>
        <li class="w3-padding-16">
          internationale Konsumgütermesse<br />&nbsp;
        </li>

        <!-- <li class="w3-padding-16">
          <h2>April 2023</h2>
          <span class="w3-opacity">in Frankfurt am Main</span>
        </li> -->
        <li class="w3-light-grey w3-padding-24">
          <a
            href="https://tendence.messefrankfurt.com/frankfurt/de.html"
            target="_blank"
          >
            <button class="w3-button hjb-primary w3-padding-large">
              Zur Messewebsite
            </button>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <!-- Contact -->
  <div class="w3-container anchor" id="contact">
    <h1 class="w3-xxxlarge hjb-text-secondary"><b>Kontakt.</b></h1>
    <hr style="width: 100px; border: 5px solid #44c4d3" class="w3-round" />
  </div>
  <div class="w3-row-padding">
    <div class="w3-col m6 w3-margin-bottom">
      <ul class="w3-ul w3-light-grey w3-center">
        <li class="w3-padding-16"><h2>Jörg Behr</h2></li>
        <li>
          <img src="assets/mitarbeiter/joerg-behr.jpg" />
        </li>
        <li class="w3-padding-16"><h4>Geschäftsführer</h4></li>
        <li>
          <h6>Email</h6>
          <a href="mailto:info@joergbehr-handelsagentur.de"
            >info@joergbehr-handelsagentur.de</a
          >
        </li>
        <li>
          <h6>Mobil</h6>
          <a href="tel:0172-2198398">+49 172 2198398</a>
        </li>
      </ul>
    </div>
    <div class="w3-col m6 w3-margin-bottom">
      <ul class="w3-ul w3-light-grey w3-center">
        <li class="w3-padding-16"><h2>Tatiana Eisenhuth</h2></li>
        <li>
          <img src="assets/mitarbeiter/tatiana-eisenhuth.jpg" />
        </li>
        <li class="w3-padding-16"><h4>Backoffice</h4></li>
        <li>
          <h6>Email</h6>
          <a href="mailto:office@joergbehr-handelsagentur.de"
            >office@joergbehr-handelsagentur.de</a
          >
        </li>
        <li>
          <h6>Mobil</h6>
          <a href="tel:0174-1915306">+49 174 1915306</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="w3-row-padding last-element" style="margin-top: 75px">
    <div class="w3-col m4 w3-margin-bottom">
      <p>Jörg Behr</p>
      <p>Handelsagentur</p>
      <p>Am Bugapark 73</p>
      <p>45899 Gelsenkirchen</p>
    </div>
    <div class="w3-col m4 w3-margin-bottom">
      <p><a href="tel:0209-94740442">Tel: 0209-94740442</a></p>
      <p><a href="fax:0209-94740443">Fax: 0209-94740443</a></p>
      <p><a href="tel:0172-2198398">Mobil: 0172-2198398</a></p>
    </div>
    <div class="w3-col m4 w3-margin-bottom">
      <p>
        <a href="mailto:info@joergbehr-handelsagentur.de"
          >info@joergbehr-handelsagentur.de</a
        >
      </p>
      <p>
        <a href="https://www.joergbehr-handelsagentur.de"
          >www.joergbehr-handelsagentur.de</a
        >
      </p>
    </div>
  </div>
  <!-- End page content -->
</div>
