  <!-- Photo carousel-->
  <ngb-carousel style="width: 50%;" id="photo-gallery-carousel">
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img src="assets/images/4868_Messe_Hausmesse_Fj-So_2021_0013.jpg" width=100%>
      </div>
      <div class="carousel-caption">
        <span>Nulla vitae elit libero, a pharetra augue mollis interdum.</span>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img src="assets/images/512_Messe_Ambiente_2020_0002.jpg" width=100%>
      </div>
      <div class="carousel-caption">
        <span>Nulla vitae elit libero, a pharetra augue mollis interdum.</span>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img src="assets/images/4857_Messe_Hausmesse_Fj-So_2021_0001.jpg" width=100%>
      </div>
      <div class="carousel-caption">
        <span>Nulla vitae elit libero, a pharetra augue mollis interdum.</span>
      </div>
    </ng-template>
  </ngb-carousel>