import { Component } from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

@Component({
  selector: 'hjb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'handelsagentur-joerg-behr';

  sidenavOpened = false;


  screenOrientation: 'Portrait' | 'Landscape' = 'Portrait';
  device: 'Web' | 'Tablet' | 'Handset' = 'Web';

  constructor(breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.TabletPortrait,
      Breakpoints.WebPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.screenOrientation = 'Portrait'
      } else {
        this.screenOrientation = 'Landscape'
      }
    });

    breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if(result.matches) {
        this.device = 'Handset'
      }
    });

    breakpointObserver.observe([
      Breakpoints.TabletPortrait,
      Breakpoints.TabletLandscape,
      Breakpoints.TabletPortrait
    ]).subscribe(result => {
      if(result.matches) {
        this.device = 'Tablet'
      }
    });

    breakpointObserver.observe([
      Breakpoints.WebPortrait,
      Breakpoints.WebLandscape,
      Breakpoints.WebPortrait
    ]).subscribe(result => {
      if(result.matches) {
        this.device = 'Web'
      }
    });
  }

  menuLinkClicked(){
    this.sidenavOpened = false;
  }
}
