import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GdprComponent } from './gdpr/gdpr.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { WebsiteWc3InteriorDesignComponent } from './website-wc3-interior-design/website-wc3-interior-design.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: WebsiteWc3InteriorDesignComponent
  },
  {
    path: 'gdpr',
    component: GdprComponent
  },
  {
    path: 'impressum',
    component: ImpressumComponent
  },
  {
    path: '404',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
