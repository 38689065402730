import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WebsiteWc3InteriorDesignComponent } from './website-wc3-interior-design/website-wc3-interior-design.component';
import { GdprComponent } from './gdpr/gdpr.component';
import { CommonModule } from '@angular/common';
import { PhotoCarouselComponent } from './photo-carousel/photo-carousel.component';
import { ImpressumComponent } from './impressum/impressum.component';

@NgModule({
  declarations: [
    AppComponent,
    WebsiteWc3InteriorDesignComponent,
    GdprComponent,
    PhotoCarouselComponent,
    ImpressumComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    MaterialModule,
    NgbModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
