import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'hjb-website-wc3-interior-design',
  templateUrl: './website-wc3-interior-design.component.html',
  styleUrls: ['./website-wc3-interior-design.component.scss']
})
export class WebsiteWc3InteriorDesignComponent implements OnInit {

  private fragment: string | null = '';

  constructor(private route: ActivatedRoute) {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }

  ngOnInit(): void {
  }

  sidenavOpened = false;
  w3_open() {
    this.sidenavOpened = true;
  }
   
  w3_close() {
    this.sidenavOpened = false;
  }

  url: string = 'http://google.de'
}
