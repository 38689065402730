<!-- Sidebar/menu -->
<nav id="mySidebar" 
  class="hjb-sidebar hjb-primary w3-collapse w3-top w3-large w3-padding"
  style="z-index:3;font-weight:bold; height: 100%;"
  [ngClass]="{ 'sidenav-opened' : sidenavOpened === true, 'sidenav-closed' : sidenavOpened === false, 'handset': device === 'Handset', 'tablet': device === 'Tablet', 'web': device === 'Web' }">
  <br>
  <div class="w3-container">
    <!-- <img src="assets/HandelsagenturJoergBehr.png" style="width:100%; margin-bottom: 30px;"> -->
    <img *ngIf="screenOrientation==='Portrait' || device === 'Web'" src="assets/HandelsagenturJoergBehr.png" style="width:100%; margin-bottom: 30px;">
    <img *ngIf="screenOrientation==='Landscape' && device !== 'Web'" src="assets/HandelsagenturJoergBehr_flach.png" style="width:50%; border-bottom: 1px solid #44c4d3">
  </div>
  <div class="w3-bar-block" style="display: flex; flex-direction: column; flex-grow: 1;">
    <a [routerLink]="['/home']" fragment="about" class="w3-bar-item w3-button hjb-menu-link" (click)="menuLinkClicked()">Über uns</a>
    <a [routerLink]="['/home']" fragment="showroom" class="w3-bar-item w3-button hjb-menu-link" (click)="menuLinkClicked()">Eindrücke</a>
    <a [routerLink]="['/home']" fragment="partner" class="w3-bar-item w3-button hjb-menu-link" (click)="menuLinkClicked()">Partner</a>
    <a [routerLink]="['/home']" fragment="appointments" class="w3-bar-item w3-button hjb-menu-link" (click)="menuLinkClicked()">Termine</a>
    <a [routerLink]="['/home']" fragment="contact" class="w3-bar-item w3-button hjb-menu-link" (click)="menuLinkClicked()">Kontakt</a>
    <div class ="spacer" style="display: flex; flex-direction: column; flex-grow: 1;"></div>
    <a routerLink="/impressum" class="secondary-link w3-bar-item w3-button hjb-menu-link" (click)="menuLinkClicked()">Impressum</a>
    <a routerLink="/gdpr" class="secondary-link w3-bar-item w3-button hjb-menu-link" (click)="menuLinkClicked()">Datenschutz</a>
  </div>
</nav>

<!-- Top menu on small screens -->
<header class="hjb-header w3-container w3-top w3-hide-large hjb-primary w3-xlarge w3-padding">
  <button class="w3-button hjb-hamburger-menu w3-margin-right" (click)="sidenavOpened = !sidenavOpened">☰</button>
  <div class="spacer"></div>
  <img *ngIf="screenOrientation==='Portrait'" src="assets/HandelsagenturJoergBehr.png" style="width:50%;">
  <img *ngIf="screenOrientation==='Landscape'" src="assets/HandelsagenturJoergBehr_flach.png" style="width:50%;">

  <!-- <img src="assets/HandelsagenturJoergBehr_flach.png" style="width:70%;"> -->
</header>

<!-- Overlay effect when opening sidebar on small screens -->
<div  class="w3-overlay w3-hide-large" style="cursor:pointer" title="close side menu"
  id="myOverlay"></div>

<router-outlet></router-outlet>